import React, {
	useEffect
}from "react"
import {
	Layout, ConfigProvider
}from "antd"
import {
	useDispatch,
	useSelector
}from "react-redux"
import {
	hot
}from "react-hot-loader/root"
import {
	Router
}from "react-router-dom"
import history from "./helpers/history"
import "./index.css"
import "./App.css"
import enUS from 'antd/lib/locale/en_US'
import ruRU from 'antd/lib/locale/ru_RU'
import moment from "moment"
import Routes from "./routes"
import Bowser from "bowser"
import ErrorBoundary from "./components/ErrorBoundary"
import Header from "./components/Header"
import Footer from "./components/Footer"
import CustomBreadcrumb from "./components/CustomBreadcrumb"
const { Content } = Layout

const App = () => {
	const { roles, user, userType } = useSelector(state => state.userReducer)
	const isRu = useSelector(state => state.globalReducer.isRu)

	useEffect(() => {
		isRu ? moment.locale('en') : moment.locale('ru')
	}, [isRu])

	return (
		<Router history={ history }>
			<ConfigProvider locale={ isRu ? enUS : ruRU }>
				<Layout
					className={ ` ${ (Bowser.name === 'Internet Explorer' || Bowser.name === 'Microsoft Edge') ? "ie-browser" : '' } ${ roles.includes('registeredUser') ? 'main__content' : '' }` }
					style={ {
						maxHeight : "100%",
						minHeight : "100vh"
					} }
				>
					<Header />
					<Layout
						className="main-middle-layout"
						style={ {
						  backgroundColor: '#FFFFFF'
						} }
					>

						<Content
							style={ {
								display       : 'flex',
								flexDirection : 'column'
							} }
						>
							<ErrorBoundary isRu={ isRu }>
								 <CustomBreadcrumb />
								<Routes />
							</ErrorBoundary>
						</Content>
					</Layout>
					<Footer />
				</Layout>
			</ConfigProvider>
		</Router>
	)
}
export default hot(App)
